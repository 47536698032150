.content-container{
  background-color: var(--App-V2-color-blue-0);
}
.faq-main-container{
  background-color: var(--default-bg);
}
.App {
  background-color: var(--default-bg);
  text-align: center;  
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
}
.headerCon{
  background-color: var(--default-bg);
}

.z-index-1{
  z-index: 0;
}
.z-index-2{
  z-index: 2;
}
.z-index-4{
  z-index: 4;
}
.content-1{
  font-family: monospace;
  color: #fff7f7;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}