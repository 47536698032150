
.content-container{
    margin-top: 0 !important;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;    

}
.content-container_1{
    backdrop-filter: blur(2px) !important;
    background-color: var(--default-bg);
}
.content-container.left{
    transform: translateX(-100px);
    opacity: 0;
}
.content-container.active{    
    margin: 0;
    transform: translateX(0);
    opacity: 1;    
}
.blog-post{
    text-align: center !important;
    width: 100%;    
    font-weight: normal !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: auto;
    margin-top: 50px ;
    margin-bottom: 50px ;
    flex-direction: column !important;
    z-index: 50;
    background-color: rgba(35,35,35,.8);
    backdrop-filter: blur(2px);
    border-radius: 15px;
    padding: 15px;
}
@media only screen and (min-width: 1050px){
    .blog-post{
        background-color: rgba(35,35,35,.5);
        backdrop-filter: blur(1px);
    }
}
.blog-post p {
    font-size: 1.15rem ;
    font-weight: normal ;
}
@media only screen and (max-width: 798px){
    .content-container{
        padding-left: 20px;
        padding-right: 20px;
    }
    .blog-post{
        width: 100%;
    }
    .blog-post p{
        font-size: 1rem;
    }
    .blog-post h3{
        font-size: 1.15rem;
        font-weight: bold;
    }
}
@media only screen and (min-width: 798px){
    .content-container{
        padding: 0;
    }
    .blog-post{
        width: 750px;
    }
}