.Content-Box{
    position: relative;
    top: 0;
    height: auto !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    padding-top: 0;
    z-index: 50;
    background-color: rgba(226, 217, 217, 0) !important;
    align-items: center;
}
.Content__Column{
    height: auto;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0);
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px; margin-top: 50px;
}

.First__{
    max-height: 100vh !important;
    min-height: 100vh !important; 
    margin-top: 0 !important;
}

.App-particles__container {
    overflow: hidden !important;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 0;
    width:100%;
    height:100vh;

    z-index: 60;
  }
  .App-particles__container canvas {
    transition: 0.2s all ease-in-out;
    z-index: 0;
    height: 100%;
    width: 100%;
  }
  .Content__Column_Svg{
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: 100vh;
    position: absolute;
    top: 0;
}
  .Content__Column_headline {
    text-align: center;
    box-sizing: border-box;
    align-items: center;
    width: inherit;
    height: auto;
    color: #fff;
    font-weight: monospace;
    position: absolute;
    top: 85px;
  }

  
@media only screen and (min-width: 900px) {
    .Content__Column{
        width: 900px !important;
    }
}

















@media only screen and (min-width: 1000px){
    .Content-Box{
        width: 100%;

    }
} 

@media only screen and (min-width: 1500px) {
    .Content-Box{
        width: 100%;

    }
    
}
@media only screen and (min-width: 2000px) {
    .Content-Box{
        width: 100%;
   
    }
    
}
@media only screen and (min-width: 2200px) {
    .Content-Box{
        width: 100%;
        
    }
    
}
@media only screen and (max-width: 700px) {
    .Content-Box{
        width: 100%;
        padding: 0;
        
    }
    
}
@media only screen and (max-width: 600px){
    .Content__Column_title{
        visibility: hidden;
    }
    .Content__Column_sub{
        visibility: hidden;
    }
}
@media only screen and (max-height: 600px){
    .Content__Column_title{
        visibility: hidden;
    }
    .Content__Column_sub{
        visibility: hidden;
    }    
}