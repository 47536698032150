.mnu-icn{
   cursor:pointer;
}

.about {
    $cubic: cubic-bezier(0.64, 0.01, 0.07, 1.65);
    $transition: 0.6s $cubic;
    $size: 30px;
    position: fixed;
    z-index: 51;
    bottom: 10px;
    right: 10px;
    width: $size;
    height: $size;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    transition: all 0.2s ease;
    overflow: none;
 
    .bg_links {
       width: $size;
       height: $size;
       border-radius: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
       background-color: rgba(#000000, 0.2);
       border-radius: 100%;
       backdrop-filter: blur(5px);
       position: absolute;cursor: pointer;
    }
 
    .logo {
       width: $size;
       height: $size;
       z-index: 9;
       background-image: url(../../Gif/icons8-teilen-2.svg); 
       background-size: 50%;
       background-repeat: no-repeat;
       background-position: 10px 7px; 
       opacity: 0.9;
       transition: all 1s 0.2s ease;
       bottom: 0;
       right: 0;
       
    }
 
    .social {
       opacity: 0;
       right: 0;
       bottom: 0;
 
       .icon {
          width: 100%;
          height: 100%;
          background-size: 20px;
          background-repeat: no-repeat;
          background-position: center;
          background-color: transparent;
          display: flex;
          transition: all 0.2s ease, background-color 0.4s ease;
          opacity: 0;
          border-radius: 100%;
       }
 
       &.portfolio {
          transition: all 0.8s ease;
 
          .icon {
             background-image: url(https://rafaelavlucas.github.io/assets/codepen/link.svg);
          }
       }
 
       &.dribbble {
          transition: all 0.3s ease;
          .icon {
             background-image: url(../../Gif/security.svg);
          }
       }
 
       &.linkedin {
          transition: all 0.8s ease;
          .icon {
             background-image: url(https://rafaelavlucas.github.io/assets/codepen/linkedin.svg);
          }
       }
    }
 
    &:hover {
       width: 105px;
       height: 105px;
       transition: all $transition;
 
       .logo {
          opacity: 1;
          transition: all 0.6s ease;
       }
 
       .social {
          opacity: 1;
 
          .icon {
             opacity: 0.9;
          }
 
          &:hover {
             background-size: 28px;
             .icon {
                background-size: 65%;
                opacity: 1;
             }
          }
 
          &.portfolio {
             right: 0;
             bottom: calc(100% - 40px);
             transition: all 0.3s 0s $cubic;
             .icon {
                &:hover {
                   background-color: #698fb7;
                }
             }
          }
 
          &.dribbble {
             bottom: 45%;
             right: 45%;
             transition: all 0.3s 0.15s $cubic;
             .icon {
                &:hover {
                   background-color: #ea4c89;
                }
             }
          }
 
          &.linkedin {
             bottom: 0;
             right: calc(100% - 40px);
             transition: all 0.3s 0.25s $cubic;
             .icon {
                &:hover {
                   background-color: #0077b5;
                }
             }
          }
       }
    }
 }
 /*
 @import url("https://fonts.googleapis.com/css?family=Oxygen:700&display=swap");
 
 body {
    margin: 0;
 }
 
 $bg: #171f30;
 $c-01: #5389a6;
 $c-02: #a6dcee;
 
 $font-01: "Oxygen", sans-serif;
 
 .content {
    width: 100%;
    height: 100vh;
    background-color: $bg;
    display: flex;
    justify-content: center;
    align-items: center;
 
    .loading {
       width: 80px;
       height: 50px;
       position: relative;
 
       p {
          top: 0;
          padding: 0;
          margin: 0;
          color: $c-01;
          font-family: $font-01;
          animation: text 3.5s ease both infinite;
          font-size: 12px;
          letter-spacing: 1px;
 
          @keyframes text {
             0% {
                letter-spacing: 1px;
                transform: translateX(0px);
             }
 
             40% {
                letter-spacing: 2px;
                transform: translateX(26px);
             }
 
             80% {
                letter-spacing: 1px;
                transform: translateX(32px);
             }
 
             90% {
                letter-spacing: 2px;
                transform: translateX(0px);
             }
 
             100% {
                letter-spacing: 1px;
                transform: translateX(0px);
             }
          }
       }
       span {
          background-color: $c-01;
          border-radius: 50px;
          display: block;
          height: 16px;
          width: 16px;
          bottom: 0;
          position: absolute;
          transform: translateX(64px);
          animation: loading 3.5s ease both infinite;
 
          &:before {
             position: absolute;
             content: "";
             width: 100%;
             height: 100%;
             background-color: $c-02;
             border-radius: inherit;
             animation: loading2 3.5s ease both infinite;
          }
 
          @keyframes loading {
             0% {
                width: 16px;
                transform: translateX(0px);
             }
 
             40% {
                width: 100%;
                transform: translateX(0px);
             }
 
             80% {
                width: 16px;
                transform: translateX(64px);
             } 
 
             90% {
                width: 100%;
                transform: translateX(0px);
             }
 
             100% {
                width: 16px;
                transform: translateX(0px);
             }
          }
          @keyframes loading2 {
             0% {
                transform: translateX(0px);
                width: 16px;
             }
 
             40% {
                transform: translateX(0%);
                width: 80%;
             }
 
            80% {
                width: 100%;
                transform: translateX(0px);
             }
 
             90% {
                width: 80%;
             transform: translateX(15px);
             }
             100% {
                transform: translateX(0px);
                width: 16px;
             }
          }
       }
    }
 }
 */