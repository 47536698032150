
#container-contact-hydrateRoot{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.Contact-Container{
    width: 100% ;
    top: 0;
    left: 0;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    background-color: #32323200;
    display: flex;        
    text-align: center;
    border-radius: 0;
    border: none; 
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 249, 249, 0); 
    padding-top: 0;
    padding-left: 10px;
    color: #fff;
    padding-right: 10px;
}
.view-height{
    padding-top: 0;
    height: 100% !important;
    background-color: rgba(14, 13, 13, 0); 
    margin-top: 150px;

    color: #000;
    margin-bottom: 60px;
}
.view-height legend{
    color: #fff !important;
}
.__black{
    color: #000 !important;
}
#submit{
    color: rgb(45,180,70);
}
.Contact-Container button{
    background-color: rgb(55,55,55);
    border-radius: 10px;
    margin: 5px;
}


.Contact-Container input{
    box-sizing: border-box !important;
    border: none; 
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: .3rem;
    text-align: center !important;
    font-size: 1rem;
    color: rgb(45,180,70);
    background-color: rgb(77,77,77);
    height: 40px;
}
.Contact-Container textarea{
    box-sizing: border-box !important;
    border: none; 
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: .3rem;
    text-align: center !important;
    font-size: 1rem;
    color: rgb(45,180,70);
    background-color: rgb(77,77,77);
}
.Contact-Container input:focus{
    color: rgb(45,180,70);
    background-color: rgb(77,77,77);
}
.Contact-Container .Legend-Footer{
    font-size: 1rem;
    mix-blend-mode: difference;
}
.footer-p{
    mix-blend-mode: difference !important;
}
.form input{
    width: 100%;
}
.Contact-Container textarea{
    resize: none;
    height: 100px;
    padding: 10px;
    color: rgb(45,180,70);
    background-color: rgb(77,77,77);
    border-radius: 5px;
    bottom: none !important;
}
.form textarea{
    width: 100%;
}
.Contact-Container textarea:focus{
    color: rgb(45,180,70);
    background-color: rgb(77,77,77);
}
.Contact-Container button{
    width: 150px;
    height: 30px;
    cursor: pointer;
}
.Text-Input{
    width: 100%;
}
#c-title {
    font-size: 2rem;
    font-family: monospace;
    font-weight: bold;
    font-variant: small-caps;    
    mix-blend-mode: difference;
    text-align: center;
}
.Legend-Footer {
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-family: monospace;
}

.footer-p{
    font-family:  sans-serif;
    font-weight: 900;
    font-size: .9rem;
    mix-blend-mode: difference;
}
.info-box{
    text-align: center;
    color: black;
    font-size: .75rem;
    background-color: rgb(255, 255, 255);
    font-weight: 900;
    width: 100% !important;
    height: auto !important;
    padding: 5px !important;

}
.form{
    border-radius: 10px !important;
    background-color: #fff;
    top: 20%;
    width: auto !important;
    border-radius: 5px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    display: flex;
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
    left: 0;
    right: 0;
    position: relative;
    flex-direction: column;
    /* z-index: 1; */
    text-align: center;
    align-items: stretch;
   
}
.ReCAPTCHA-container{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto !important;
}
.Info-DSGVO-continer{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    height: auto !important;
    text-align: center;  
    border-radius: 10px ;
}
.form__opacity{
    background-color: rgba(255, 255, 255, 0);

}
.text-paragraph{

    color: #fff;
}



.success-container{
    height: auto;
    width: 758px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.SVG_Connect_Container{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
}
@media only screen and (max-width: 758px){
    .success-container{
        width: 100%;
    }
    .form{
        margin-left: 0;
        margin-right: 0;
    }
    .SVG_Connect_Container{
        padding-left: 0;
        padding-right: 0;
    }
}
@media only screen and (min-width: 1300px){

    .SVG_Connect_Container{
        padding-left: 10%;
        padding-right: 10%;
    }
}