
/*NAV-v2*/

  .wrapper{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /*background: linear-gradient(-135deg, #c850c0, #4158d0);*/
    /* background: linear-gradient(375deg, #1cc7d0, #2ede98); */
   /* background: linear-gradient(-45deg, #e3eefe 0%, #efddfb 100%);*/
    /* clip-path: circle(25px at calc(0% + 45px) 45px); */
    background: rgba(0, 0, 0, 0);
    clip-path: circle(25px at calc(100% - 45px) 45px);
    transition: all 0.3s ease-in-out;
    z-index: 51;
  }
  #active:checked ~ .wrapper{
    clip-path: circle(75%);
    background: rgba(0, 0, 0);
  }
  

  .menu-btn{
    position: fixed;
    z-index: 99;
    right: 5px;
    /* left: 20px; */
    top: 5px;
    height: 45px;
    width: 45px;
    text-align: center;
    line-height: 50px;
    border-radius: 0;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    /*background: linear-gradient(-135deg, #c850c0, #4158d0);*/
    /* background: linear-gradient(375deg, #1cc7d0, #2ede98); */
   /* background: linear-gradient(-45deg, #e3eefe 0%, #efddfb 100%); */
    background: rgba(0, 0, 0, 0);
    backdrop-filter: blur(3px);
    transition: all 0.3s ease-in-out;
  }
  .mobile{
    top: 11px;
    right: 0 !important;
  }
  #active:checked ~ .menu-btn{
    color: #fff;
  }
  #active:checked ~ .menu-btn i:before{
    content: "\f00d";
  }
  .wrapper ul{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: left;
  }
  .wrapper ul li{
    margin: 0 0;
  }
  .wrapper ul li a{
    color: none;
    text-decoration: none;
    font-size: 30px;
    font-weight: 500;
    padding: 5px 30px;
    color: #fff;
    border-radius: 50px;
    background: #000;
    position: relative;
    line-height: 50px;
    transition: all 0.3s ease;
  }
  .wrapper ul li a:after{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; /* if the font is 'Oswald'*/
    border-bottom: 1px solid white;
    transform: scaleY(0);
    z-index: -1;
    color:rgb(202, 197, 197);
  }
  .wrapper ul li a:hover:after{
    transform: scaleY(1);
  }
  .wrapper ul li a:hover{
    color: #fff;
  }
  input[type="checkbox"]{
    display: none;

  }

  .content_maunv2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    text-align: center;
    width: 100%;
    color: #202020;
  }
  .content .title{
    font-size: 40px;
    font-weight: 700;
  }
  .content p{
    font-size: 35px;
    font-weight: 600;
  }
  
  @keyframes rotate {
    0%{
      filter: hue-rotate(0deg);
    }
    100%{
      filter: hue-rotate(360deg);
    }
  }
  