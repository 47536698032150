.header-v2-main{
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    height: 60px;
    background-color: rgba(35, 64, 90, 0);
    transition: all .3s ease-in-out;
}
.v2-header-container{
    width: 100%;
    padding-left: 10px;
    padding-right: 0;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.v2-header-background-transition{
    background-color: rgba(35, 64, 90, 1) !important;
}

/*! ICON-BAR-MENU */
.v2-iconbar{
    height: 100%;
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1 auto;    
}
.v2-icons span {
    font-family: var(--font-family);
    font-size: 1.2em;
}
.v2-icons{
    cursor: pointer;
}
.v2-icons-svg{
    padding: auto;    
}
.v2-label-name{
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
    border: .09rem solid white;
    font-weight: 550;
    color: var(--font-color-light)
}
/*! ICON-BAR-MENU ENDS */

/*! SLIDE MENU */
.v2-menubar{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 60px; 
    right: 0;
    transform: translateX(100vw); 
    box-sizing: border-box;
    z-index: 50;
    background-color: var(--App-V2-color-blue-1);
    transition: all 0.3s ease-in-out;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}
.v2-clicked-menu{
    transform: translateX(0);
    opacity: 1; 
}
.v2-menu-wrapper{
    width: 50%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    padding-top: 0;
    box-sizing: border-box;
    flex-direction: column;
}
.v2-menu-wrapper a{
    margin-top: 5px; 
    margin-bottom: 5px;
    font-size: 1.5rem;
    font-family: var(--font-family);
    font-weight: var(--font-weight-large);
    cursor: pointer;
}

/*! SLIDE MENU END */





/*? Mobile */
@media only screen and (max-width: 480px){
    .header-v2-main{
        height: 40px;
    }   
    .v2-menubar{
        top: 40px;
    }
}

/*? Tablet */
@media only screen and (max-width: 755px){
    .header-v2-main{
        height: 50px;
    }   
    .v2-menubar{
        top: 50px;
    }
}