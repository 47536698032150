.Menu-Container{
    box-sizing: border-box;
    background-color: rgba(60, 60, 60, 1);
    position: fixed;
    right: -50%;
    bottom: 6%;
    border-radius: 10px;
    opacity: 1;
    z-index: 99;
    overflow: hidden;
    width: 150px;
    height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    color: #000000;
    padding-top: 20px;
    user-select: none;
    
}
.open{
    position: fixed;
    animation: open-Menu 1s ease-in-out;
    right: calc(0% - 0px );
    
}
.close{
    
    animation: close-Menu 1s ease-in-out;
    right: calc(-50% - 0px );
}
.open-menu{
    position: fixed;
    width: 120px;
    height: 45px;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: rgba(120,120,120,0);
    bottom: 2%;
    right: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: monospace;
    font-variant: small-caps;
    color: #ffffff;
    font-size: 2.1rem;
    transition: 1s;
    font-weight: 700;
    text-align: center;
    z-index: 50;
    user-select: none;
}
.open-menu:hover{
    color: rgb(45,160,35);
}
.opacity-1{
    animation: opacity-1  ;
    opacity: 1;
}
.opacity-0{
    animation: opacity-0  ;
    opacity: 0;
}
.firstload{
    right: -200%;
}


@keyframes opacity-0{
    0%{opacity:1;}
    100%{opacity:0}
}
@keyframes opacity-1{
    0%{opacity:0;}
    100%{opacity:1}
}

@keyframes close-Menu {
    0%{
        right: 0%;
    }
    100%{
        right: -50%;
    }
}
@keyframes open-Menu {
    0%{
        right: -50%;
    }
    100%{
        right: 0%;
    }
}



.Cell-Container{
    flex: 1;
    margin: 1rem;
    max-height: 40px;
    background-color:rgb(80, 81, 81);
    color: rgba(211, 230, 224,.8);
    width: 100%;
    box-sizing: border-box;
    display: flex;
    text-align: center;
    justify-content: center;

    font-size: 1.5rem;
    font-weight: 400;
    font-family:  monospace;
    font-variant: small-caps;
    cursor: pointer;
}
.Cell-Container-1{
    transition: 2s;
}
.Cell-Container-1::after{
    content: 'about';
    position: absolute;
    width: 100%;
    background-color: rgba(80,81,81,0);
    top: 36px;
    left: 0;
    height: 50px;
    display: flex;
    text-align: center;
    justify-content: center;
    color: #ffffff;
    box-sizing: border-box;    
    opacity: 0;
    font-family:  monospace;
    font-weight: 700;
    font-size: 1.9rem;
    font-variant: small-caps;
    color:rgba(60, 60, 60, 1);

    transition: .5s;
}
.Cell-Close p{
    z-index: -5;
}
.Cell-Container-1:hover::after{
    opacity: 1;
    top: 0;
    height: 100%;
    transition: .5s;
    background-color: rgba(150,150,150,1);
}
.Cell-Close{
    background-color: rgb(60, 60, 60);
    flex: .5;
    width: 100%;
    box-sizing: border-box;
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(209, 81, 81);
    font-weight: 700;
    font-variant: small-caps;
    font-size: 1.5rem;
    font-family:  monospace;    
    max-height: 50px;
    cursor: pointer;
    transition: .8s;
    z-index: 120;

}
.Cell-Close::after{
    content: 'close';
    position: absolute;
    background-color: rgba(160, 160, 160, 0.767);
    width: 100%;
    z-index: 150;
    bottom: 0;
    left: 0;
    height: 20.5%;
    opacity: 0;
    display: flex;
    color: #fff;
    justify-content: center;
    text-align: center;
    transition: .8s;
    box-sizing: border-box;
    border: none;
    z-index: 10;
}
.Cell-Close:hover::after{
    height: 100%;
    opacity: 1;
    background-color: rgba(160,40,40,1);
    
}
.Cell-Close:hover{
    color: rgb(240, 50, 50);
    
}
.Cell-Container-3{
    transition: .5s ease-in-out;
}
.Cell-Container-3:hover{
    background-color: rgb(170,170,170);
    color:rgba(60, 60, 60, 1);
}
.Cell-Container-2{
    transition: .5s ease-in-out;
}
.Cell-Container-2:hover{
    background-color: rgb(170,170,170);
    color:rgba(60, 60, 60, 1);
}


