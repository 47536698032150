#container-impressum-hydrate{
    min-height: 100vh !important;
    width: 100%;

}
.Impressum-Container{
    position: relative;
    margin-top: 0;
    padding: 60px;
    bottom: 0;
    left: 0;
    width: 100%;
    box-Sizing: border-box;
    text-Align: left;
    font-Size: .8rem;
    font-Weight: 800;
    height: auto;
    color: #fff;
}


@media only screen and (max-width: 650px){
    .Impressum-Container{
        padding-left: 10px;
    }
}