.DSGVO-Container{
    width: 1000px;
    height: auto;
    color: rgb(255,255,255);
    font-Size: .9rem;
    text-Align: center;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 150px;
    padding-bottom: 250px;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: var(--default-bg);
    height: auto;
    margin-bottom: 50px;
}
.DSGVO-Container ul, li{
    list-style: none; 
}


@media only screen and (max-width: 600px){
    .DSGVO-Container{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
        padding-bottom: 50px;
        font-size: .8rem; 
        width: 100%;
    }   
}
@media only screen and (min-width: 1500px){
    .DSGVO-Container{
        padding-left: 20px;
        padding-right: 20px;
        width: 1000px;
    }   
}