.V2-Background{
    display: block;
    position: absolute; 
    z-index: 1; 
    width: 100%; 
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--App-V2-color-blue-0);
}
.V2-svg-waves{
    width: 100%;
    height: 250px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(180deg);    
    display: flex;
    align-items: flex-end;
}

/*! Landing Page Content */
.v2-container{
    margin-top: 200px;
    margin-bottom: 200px;
}
.v2-landing-content{
    padding-top: 50%;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    display: flex;    
    flex-direction: column;
    position: relative;
    top: 0;
    left: 0;
    z-index: 5;

}
.v2-first-landing-page-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
    transition: all ease-in-out .8s;
    opacity: 0;
    transform: translateY(50px);
}
.v2-first-landing-page-content h1{
    font-family: var(--font-family);
    font-size: 2.5rem;
    font-weight: 500;
    color: white;
}
.v2-first-landing-page-content h2{
    font-family: var(--font-family);
    font-size: 2.5rem;
    font-weight: 500;
    color: white;
}
.v2-first-landing-page-content p{
    font-family: var(--font-family);
    font-size: 1.1rem;
    color: var(--font-color-light)
}
/*? Observer Styles */

.v2-activated {
    opacity: 1;
    transform: translateY(0);
}


/*? Observer Styles End */

/*! Landing Page End */