.search-template-scss {

    max-width: 370px;
    width: 100%;
    height: 40px;
    margin-top: 7px;
    margin-bottom: 15px;
    background: #444;
    background: rgba(0,0,0,.2);
    border-radius:  3px;
    border: 1px solid #fff;
    
    input {

      max-width: 370px;
      padding: 10px 5px;
      float: left;
      color: #ccc;
      border: 0;
      background: transparent;
      border-radius: 3px 0 0 3px;
      &:focus {
        outline: 0;
        background:transparent;
      }
    }
    
  
  }    
  .search-button-input {
      position: relative;
      float: right;
      border: 0;
      padding: 0;
      cursor: pointer;
      height: 40px;
      width: 120px;
      color: #fff;
      background: transparent;
      border-left: 1px solid #fff;
      border-radius: 0 3px 3px 0;
      &:hover {
        background: #fff;
        color:#444;
      }
      &:active {
        box-shadow: 0px 0px 12px 0px rgba(225, 225, 225, 1);
      }
      &:focus {
        outline: 0;
      }
    }