
.Landing-Pages-Container::-webkit-scrollbar{
    display: none;
}
.Landing-Pages-Container{
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    overflow-y: scroll !important;
    scroll-behavior: smooth !important;
}
.Landing-Pages-Container{
    position: relative ;
    width: 100% ;
    box-sizing: border-box;
    height: auto;    
    background-color: rgba(45, 45, 45, 0) ;    
    top: 0;
    left: 0;
    user-select: none;
    display: flex;
    
    z-index: 50;
}
.Content-Background{
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 2000px;
    z-index: 2;
    box-sizing: border-box;
    overflow: hidden !important;
    background-attachment: fixed;
    
}
.Content__Column{
    text-align: center;
    display: flex;
    justify-content: center;
}

.Content__Column h1{
    font-family: 'Signika Negative', sans-serif;
    font-weight: bold;
}
