body{
    background-color: rgba(0,0,0,0);
}
xhtm{
    background: linear-gradient(-95deg, #2c2525, #7e3954, #3b7c96, #00795a);
	background-size: 500% 500%;
    animation: gradient 30s ease infinite;
}
html{
    background-color: rgb(30,30,32);
}
.background-svg{
    position: fixed;
    overflow: hidden !important;
    left: 0;
    top: 0;
    border:0 solid #000000;height: calc(100vh - 100px); width: 100%;
    z-index: 1;

}
.columns{
    z-index: 50 !important;
}

.individual-row{
    border: 1px solid white !important;


    border-radius: 3px !important;
    background-color: rgb(70,70,70) !important;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}
