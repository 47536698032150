.v2-social-buttons{
    position: fixed;
    bottom: 20px;
    right: 0;
    width: 40px;
    box-sizing: border-box;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: auto;
    z-index: 49;
}
.v2-social-button{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}