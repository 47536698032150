
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
._default{
  box-sizing: border-box;
}
.App{

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

@media only screen and (min-width: 1050px){
  .faq-main-container{
    background-color: #111;
  } 
}
.hidden{
  display: none;
}

.containerBox{
  flex: .05;

  display: flex;
  flex-direction: row;
  z-index: 20;
}
.buttonCon{
  display: flex;
  flex: 1;
  position: relative;
  left: calc(85% - 200px);
  box-sizing: border-box;
  min-width: 100px;
  max-width: 100px;
  justify-content:center;
  flex-direction: row;
  padding:10px;

}

.textAlign-Border-Size{
  text-align: center;
  min-width: 350px;
  font-size: 1rem;
  border-radius: 10px;
  border: none;
  margin: 20px;
}
.btn{
  flex: 1;
  position: relative;
  left: 0;
  cursor: pointer;
  border-radius: 10px;
  border: none;
  min-width: 50px;
  text-decoration: none;
}

.backgroundImg{
  background-repeat: no-repeat;
  background-size: 100%;
}
.headerCon{
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 20%);
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  font-size: 2rem;
  color: rgb(3, 4, 17);
  font-variant: small-caps;
  filter: brightness(.8);
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
.welcomeCon{
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-left: 0;
  border-radius: 10px;
  top: 150px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  flex-direction: column;
  word-wrap: normal;
}

.welcomeCon h1{
  font-size: 2rem;
  padding: 15px;
  color: white;
  -webkit-background-clip: text;
  background-clip: text;
}
@media only screen and (max-width: 760px){
  .welcomeCon h1{
    font-size: 1.5rem;
    padding-top: 50px;
  }
}
.mainContainer{
  display: flex;
  top: 0;
  width: 100%;
  box-sizing: content-box;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 150px;
  background: linear-gradient(
    rgb(35, 35, 35),
    rgb(37,37,37),
    rgb(39, 39, 39),
    rgb(41, 41, 41),
    rgba(43,43,43,.5),
    rgba(43,43,43,.1)
  );
  flex-direction: column;
  position: relative;
  

}
.contentContainer{
  flex:.4;
  justify-content: center;
  display: flex;
  flex-direction: row;
  max-height: 450px;
  min-height: 450px;
  width: 100%;
  overflow: hidden;
  padding: 0;
}
.contentContainer-9{
  flex: 1;
}

.margin-t-b{
  margin-bottom: 30px;
  margin-top: 15px;
}
.margin-t{
  margin-top: 110px;
}
.blackFont{
  color:black;
  font-weight: bold;
}
.whiteFont{
  color:#fff;
  font-weight: bold;
}

.content-1{
  -moz-backgound-image: none;
  filter: brightness(.95);
}
.observed-blog{
  transform: translateY(100px);
  opacity: 0 ;
}
.post-activated{
  transform: translateY(0);
  opacity: 1 ;
}

.content-1 h3 {
  font-size: 1.5rem;
  background: -webkit-linear-gradient(
    #252525,
    #202020,
    #121212
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  
}
.contentContainer-2{
  right: 0;
  position: relative;
  transition: all 2s ease-in-out;
}


.containerCard{
  flex:.4;
  display: flex;
  flex-direction: column;
  background-color: rgb(55,55,55);
  margin-left: 5px;
  margin-right: 5px;
  padding: 1rem;
  max-width: 900px;
  min-width: 800px;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
}
.c-end{
  flex:.1;
  justify-content: center;
  display: flex;
  flex-direction: row;
  max-height: 50px;
  min-height: 50px;
  width: 100%;
  background-color: rgba(59, 58, 58, 0.904);
  color: #ffffff;
  font-family: monospace;
  font-variant: small-caps;
  font-size: 2rem;

}
.containerCard-1{
  flex:.4;
  display: flex;
  flex-direction: column;
  background-color: rgb(55,55,55);
  margin-left: 5px;
  margin-right: 5px;
  padding: 1rem;
  max-width: 900px;
  min-width: 900px;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  max-height: 800px;
  filter: blur(0px);
  min-height: 650px;
  transition: 1s;
}
.containerCard-1:hover{
  filter: blur(0px);
  transition: 1s;
}
.containerCard-1::before{
  content: '';
  position: absolute;
  left: calc(45% - 35%);
  top: calc(18vh - 125px);
  width: 750px;
  height: 300px;
  background: rgb(180, 180, 180);
  background-repeat: no-repeat;
  background-size: 100%;
  filter: brightness(1);
  opacity: 1;
  border-radius: 25px;
  box-sizing: border-box;
  transition: 1.8s;
  transform: skewY(180deg);  
  z-index: 0;
}

.containerCard-1:hover::before{
  transform: skewY(190deg);
  top: calc(15vh - 120px);
  left: calc(0vw - 1000px );
  transition: 1.5s;
}

.containerCard-1::after{
  content: 'welcome';
  position: absolute;
  font-size: 2rem;
  font-variant: small-caps;
  color: #ffffff;
  font-family: monospace;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: calc(45% - 40px);
  right: calc(50% - 5%); 
  width: 15%;
  opacity: 1;

  height: 160px;
  background: rgb(4, 30, 48);
  box-sizing: border-box;
  transition: 1.8s;
  transform: skewY(180deg);
  border-radius: 10px;
}
.containerCard-1:hover::after{
  transform: skewY(230deg);
  right: calc(0vw - 1000px);
  top: calc(20% - 40px);
  transition: 1.5s;
}

.margin-b{
  margin-bottom: 100px;
}
.containerCard:hover{
  box-shadow: inset 0 0 0 0.2rem rgba(255,255 ,255,.5);
  border-radius: 10px;
  transform:translate(0rem,-.2rem);
  border: none;
}
.content-1:hover{
  box-shadow: inset 0 0 0 0.2rem rgba(35,35 ,35,1);
  border-radius: 10px;
  transform:translate(0rem,-.2rem);
  border: none;

}
.contentTextHead{
  flex: .34;
  min-width: 100vw;
}
.bubbleCon{
  position: absolute;
  top: 0;
  z-index: 4;
  flex:1;
  padding: 0;
  left:0;
  min-width: 100vw;
}
.bubbleInCon{
  background-color: rgba(0,0,0,0);
  flex:.4;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0;

  width: 100%;
  transition: all 0.2s ease-in-out;
}

.animate{
  padding: 0;
  background-color:rgb(180,180 ,180);
  border:none;
  position: relative;
  color:rgb(180,180,180);
  filter: url('#gooey');  
  margin: 0!important;
  top: 0;
  left:0;
  min-width: 100vw;
}
.animate:focus{
  outline:none;  
}
.bubble{
  background-color:rgb(180,180 ,180);
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: -1;
  border-radius: 100%;
}
i{
  color: #0f8ec9;
  font-size: 2.5rem;
  cursor: pointer;
}
@media only screen and (max-width: 1000px) {
  .headerCon {
    background-position: center;
    background-size: auto;
  }
}