.words-container{
    position: relative;
    font-family: var(--font-family-advanced-9);
    color: rgba(255, 255, 255, 1);
    font-size: 4em;
    filter: contrast(15);
}
.word{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: switch 8s infinite ease-in-out;
    min-width: 100%;
    margin: auto;
    
}
.word:nth-child(1){
    animation-delay: -10s;

}
.word:nth-child(2){
    animation-delay: -8s;
    
}
.word:nth-child(3){
    animation-delay: -6s;
    
}

@keyframes switch{
    0%,
    5%,
    100%{filter: blur(0px); opacity: 1;}
    50%,
    80%{filter: blur(150px); opacity: 0}
}
@media only screen and (max-width: 760px){
    .words-container{
        font-size: 3em;
    }
}
@media only screen and (max-width: 450px){
    .words-container{
        font-size: 2.3em;
    }
}