.row{
  width: 100% !important;
}
.custom-columns{
  flex: 1 !important;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.wide-article-link {

  padding: 0.5rem 0.1rem 0.5rem;
  border-radius: 0;
  flex: 1;
  margin-bottom: 0;
  text-align: center;
  backdrop-filter: blur(1px) !important;
  -webkit-backdrop-filter: blur(1px) !important;
  display: flex;
  width: 100% !important;
  border-bottom: 2px solid white !important;
  border-top: 2px solid white !important;
  }

  @media only screen and (max-width: 768px){
    .paragraph-text{
      font-size: 1.1rem !important;
    }
    .anchor-link{
      font-size: 1.3rem !important;
    }
    .article-author{
      font-size: 1.1rem !important;
    }
  }
  .custom-styles-column{
    color: #fff !important;
  }
  .article-author em, a{
    color: #fff !important;
  }
  .custom-styles-column:in(a, p, em){
    text-decoration: none !important;
    font-family: var(--font-family) !important;
  }
  .article-elipsis a{
    font-weight: normal !important;
    font-family: var(--font-family) !important;
    font-size: .9rem !important;
    text-decoration: none !important;
    cursor: pointer;
  }
  .article-elipsis a:hover{
    text-decoration: underline !important;
  }
  .article-elipsis{
    color: #fff !important;
  }
  .paragraph-text{
    color: #fff !important;
    font-family: var(--font-family) !important;
  }
  .anchor-link{
    text-decoration: none !important;
    color: #fff !important;
    font-family: var(--font-family) !important;
  }

  .small-12 p {
    color: #111111;
  }
  .flex-container{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .custom-btn {
    width: 250px;
    height: 40px;
    padding: 10px 25px;
    border: 2px solid rgb(247, 243, 243);
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    color: #fff;
  }
  
  /* 3 */
.btn-3 {
  line-height: 39px;
  padding: 0;
  width: 100%;
  max-width: 250px;
  height: auto;
}

.btn-3:hover{
  background: transparent;
  color: rgb(252, 248, 248);

}
.btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-3:before,
.btn-3:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: rgb(253, 252, 252);
  transition: all 0.3s ease;
}
.btn-3:before {
  height: 0%;
  width: 2px;
}
.btn-3:after {
  width: 0%;
  height: 2px;
}
.btn-3:hover:before {
  height: 100%;
}
.btn-3:hover:after {
  width: 100%;
}
.btn-3 span:before,
.btn-3 span:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease;
}
.btn-3 span:before {
  width: 2px;
  height: 0%;
}
.btn-3 span:after {
  width: 0%;
  height: 2px;
}
.btn-3 span:hover:before {
  height: 100%;
}
.btn-3 span:hover:after {
  width: 100%;
}
