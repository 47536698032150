@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700");

.news-page-main-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 0; margin: 0; background-color: var(--App-V2-color-blue-0);
}
.Content-Container-Row{
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  padding-top: 60px;
  background-color: var(--App-V2-color-blue-0);
  border-radius: 5px;
  width: 100%;

}
.Search-Icon-Con{
  position: fixed;
  top: 5px;
  right: 100px;
  width: 70px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center; 
  background-color: rgba(10, 10, 10, 0);
  cursor: pointer;
  backdrop-filter: blur(2px);
  z-index: 99;
  border-radius: 5px;
}
@media only screen and (max-width: 768px){
  .Search-Icon-Con{
    right: 75px;
  }
}
.search-button{
  height: fit-content;
  width: fit-content;  
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}
.search-menu-box-con{
  
  position: fixed;
  max-width: 900px;
  width: 100%;
  height: auto;
  min-height: 250px;
  box-sizing: border-box;
  top: 15%;
  left: 0;
  background-color: var(--bg-dark);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99;
  padding: 20px;
  padding-top: 10px;
  border: 1px solid white;
}
.stop-scrolling{
  overflow-y: hidden !important;
}
.search-result-con{
  
  width: 100%;
  height: auto;
  max-height: 70vh;
  background-color: var(--bg-dark);
  color: rgb(8, 8, 8);
  flex: 1;
  border-top: solid 1px;
  border-radius: 5px;
  border-color: rgba(248, 245, 245,0) ;
  border-image: initial;

  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.search-bar-input-con{
  height: auto;
  position: relative;
  top: 0;
  display: flex;
  justify-content: space-between;

}
.search-result-box{
  margin-top: 10px;
  height: auto;
  width: 100%;
  background-color: var(--bg-accent);
  position: relative;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  padding-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: .2s all ease-in;
  padding-left: 10px;
  padding-right: 10px;
}
.search-result-box:hover{
  padding-top: 15px;
  border-radius: 10px;
  background-color: #212b4d !important;
}
@media only screen and (min-width: 900px) {

  .search-menu-box-con{
    left: calc(50% - 450px)
  }
}

#h3___{
  font-family: var(--font-family-advanced-1) !important;
  font-size: 1.85rem !important;
  font-weight: normal !important;
  
}
.news-page-headline{
  margin-top: 80px !important;
  position: relative !important;
  display: flex;
  flex-direction: column !important;
}

.row{
  flex:1;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 20px;
  flex-direction: column !important;
  border-radius: 8px !important;
  text-overflow: center !important;
}
.wide-article-link {
    width: 100%;
    background-color: var(--bg-dark);
    padding: 1rem 1rem 0.5rem;
    margin-bottom: 1rem;
    border-bottom-color: 1px solid white !important;
  }
  

  .custom-style{
    background-color: var(--bg-gray) !important;
    color: #fff !important;
    font-family: var(--font-family) !important;
    font-size: 1.5rem !important; 
  }
  .custom-wide-card{
    backdrop-filter: blur(2px) !important;
    background-color: var(--bg-dark) !important;
    color: #fff !important;
    font-family: var(--font-family) !important;
    font-size: 1.5rem !important;
  }
  .custom-wide-row{
    align-items: center !important;
    justify-content: center !important;
  }
  @media only screen and(max-width: 900px) {
    .news-side-right-container{
      display: none !important;
    }
    .news-side-left-container{
      display: none !important;
    }
    .row{padding: 0;}
  }
