.footer-container{
    position: relative;

    bottom: 0;
    left: 0;
    height: auto !important;
    width: 100%;
    background-color: var(--bg-dark) !important;    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 50;
}
.section-heading{
    display: flex;
    text-align: start;
    justify-content: center;
    align-items: center;
    margin-left: -20px;
}
.footer-left-widget{
    margin: 20px !important;
    
}
.footer-text-container{
    color: #fff;
    display: flex;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    position: relative;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    flex-direction: column;
}
.footer-link-container{
    color: #fff !important;
    display: block;
    margin: 2px;
    display: block;

}
.footer-link-container {
    font-weight: bold;
    font-size: .85em;    
}
.footer-text-div-con {
    display: flex;
    position: relative;
    margin: 8px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.footer-text-div-con span{
    font-weight: bold;
    font-size: .9rem;  
    text-decoration:  none !important;
}

.footer-linked-column-container{
    width: auto;
    height: auto;
    position: relative;
    padding: .5em;
    display: flex;
    text-align: center;
    margin: 5px;
}
.footer-svg-container {
    position: absolute;
    height: auto;
    width: auto;
    display: flex;
    right: calc(50% - 100px);
}

@media only screen and (max-width: 700px){
    .footer-svg-container{
        right: calc(0% + 20px);
    }  
    .col-md-12{
      
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      text-align: center !important;
    }  
 
}
@media only screen and (max-width: 310px){
    .footer-svg-container{
        display: none;
    }
}
@media only screen and (min-width: 1300px){
  .list_items_row{
    align-self: flex-start;
  }
}
/* FOOTER V2 */
.col-lg-8{
  width: auto !important;
}

.animate-border {
  position: relative;
  display: block;
  width: 115px;
  height: 3px;
  background: #007bff; }

.animate-border:after {
  position: absolute;
  content: "";
  width: 35px;
  height: 3px;
  left: 0;
  bottom: 0;
  border-left: 10px solid #fff;
  border-right: 10px solid #fff;
  -webkit-animation: animborder 2s linear infinite;
  animation: animborder 2s linear infinite; }

@-webkit-keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px); } }

@keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px); } }

.animate-border.border-white:after {
  border-color: #fff; }

.animate-border.border-yellow:after {
  border-color: #F5B02E; }

.animate-border.border-orange:after {
  border-right-color: #007bff;
  border-left-color: #007bff; }

.animate-border.border-ash:after {
  border-right-color: #EEF0EF;
  border-left-color: #EEF0EF; }

.animate-border.border-offwhite:after {
  border-right-color: #F7F9F8;
  border-left-color: #F7F9F8; }

/* Animated heading border */
@keyframes primary-short {
  0% {
    width: 15%; }
  50% {
    width: 90%; }
  100% {
    width: 10%; } }

@keyframes primary-long {
  0% {
    width: 80%; }
  50% {
    width: 0%; }
  100% {
    width: 80%; } } 

.footer_row{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 5px !important;
    flex-direction: row !important;
    flex-wrap: wrap;

}
.footer_row1{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
    flex-direction: row !important;
    flex-wrap: wrap;

}
.dk-footer {
  padding: 75px 0 0;
  background-color: #151414;
  position: relative;
  z-index: 50; }
  .dk-footer .contact-us {
    margin-top: 0;
    margin-bottom: 30px;
    padding-left: 80px; }
    .dk-footer .contact-us .contact-info {
      margin-left: 50px; }
    .dk-footer .contact-us.contact-us-last {
      margin-left: -80px; }
  .dk-footer .contact-icon i {
    font-size: 24px;
    top: -15px;
    position: relative;
    color:#007bff; }

.dk-footer-box-info {
  position: absolute;
  top: 0px;
  background: #202020;
  padding: 40px;
  z-index: 2; }
  .dk-footer-box-info .footer-social-link h3 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 25px; }
  .dk-footer-box-info .footer-social-link ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
  .dk-footer-box-info .footer-social-link li {
    display: inline-block; }
  .dk-footer-box-info .footer-social-link a i {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    background: #000;
    margin-right: 5px;
    color: #fff; }
    .dk-footer-box-info .footer-social-link a i.fa-facebook {
      background-color: #3B5998; }
    .dk-footer-box-info .footer-social-link a i.fa-twitter {
      background-color: #55ACEE; }
    .dk-footer-box-info .footer-social-link a i.fa-google-plus {
      background-color: #DD4B39; }
    .dk-footer-box-info .footer-social-link a i.fa-linkedin {
      background-color: #0976B4; }
    .dk-footer-box-info .footer-social-link a i.fa-instagram {
      background-color: #B7242A; }

.footer-awarad {
  margin-top: 285px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -moz-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .footer-awarad {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    margin-left: 0;
    padding-top: 15px; 
    text-align: center;
}

.footer-info-text {
  margin: 26px 0 32px; }

.footer-left-widget {
  padding-left: 0;
  text-align: right;
}
.col-lg-6 span{
  float: right;
}

.footer-widget .section-heading {
  margin-bottom: 35px; }

.footer-widget h3 {
  font-size: 24px;
  color: #fff;
  position: relative;
  margin-bottom: 15px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content; }

.footer-widget ul {
  width: 50%;
  float: right;
  list-style: none;
  margin: 0;
  padding: 0; }

.footer-widget li {
  margin-bottom: 18px; }

.footer-widget p {
  margin-bottom: 27px; }

.footer-widget a {
  color: #878787;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  .footer-widget a:hover {
    color: #007bff; }

.footer-widget:after {
  content: "";
  display: block;
  clear: both; }


/* ==========================

    Contact

=============================*/
.contact-us {
  position: relative;
  z-index: 2;
  margin-top: 65px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.contact-icon {
  position: absolute; }
  .contact-icon i {
    font-size: 36px;
    top: -5px;
    position: relative;
    color: #007bff; }

.contact-info {
  margin-left: 75px;
  color: #fff; }
  .contact-info h3 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 0; }

.copyright {
  padding: 28px 0;
  margin-top: 55px;
  height: 150px;
  background-color: #202020;
  z-index: 65;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: -20px;
}
  .copyright span,
  .copyright a {
    color: #878787;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear; }
  .copyright a:hover {
    color:#007bff; }

.copyright-menu ul {
  text-align: right;
  margin: 0; }

.copyright-menu li {
  display: inline-block;
  padding-left: 20px; }

.back-to-top {
  position: relative;
  z-index: 2; }
  .back-to-top .btn-dark {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    padding: 0;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #2e2e2e;
    border-color: #2e2e2e;
    display: none;
    z-index: 999;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .back-to-top .btn-dark:hover {
      cursor: pointer;
      background: #FA6742;
      border-color: #FA6742; }
    .footer_container{
        margin-right: auto;
        width: 100%;
        margin-left: auto;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: -webkit-auto;    
        
    }
    
    .copyright-menu{display: flex; justify-content: center; align-items: center; text-align: center;}
      @media only screen and(max-width: 767px){
        .footer_row{
            flex-direction: column !important;
        }
        
        .footer-left-widget {
            padding-left: 0 !important; 
        }
        .footer-left-widget{
            padding-left: 0 !important;
        }
        .footer_container{
            width: 100% !important;
            padding-right: 0 !important;
            padding-left: 0 !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            box-sizing: border-box;
        }
    }
    .footer-left-widget {
        padding-left: 0px !important;
    }