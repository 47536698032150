.individual{
    border: none !important;
    color: white !important;
    font-family: "Signika Negative", sans-serif !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    width: 1000px !important;
}
.inner-con{
    font-size: 1.2rem !important;
    
}
.inner-con{
    background-color: #141414c2;
    border: none !important;
    width: auto !important;
    height: auto !important;
    box-sizing: border-box;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    backdrop-filter: blur(2px) !important;
    border-radius: 10px !important;
    padding: 20px !important;
}
.callout-box{
    margin-top: 50px !important;
    margin-bottom: 50px !important;

}
.callout-box h3{
    font-size: 1.1rem !important
}
.inner-con p{
    font-family: "Signika Negative", sans-serif !important;
    font-weight: normal !important;
    font-size: 1.1rem !important;
}
.inner-con h6{
    align-self: center !important;
    font-family: 'Signika Negative', sans-serif !important;
    color: rgb(221, 221, 221) !important;
    font-size: 1.4rem !important
}
@media only screen and (min-width: 1000px){
    .individual{
        font-size: 1.5em !important;
        font-weight: normal !important;
    }
    .inner-con h6{
        font-size: 1.4em !important;
        font-weight: normal !important;
    }
    
}
@media only screen and (max-width: 1000px){
    .individual{
        font-size: 1em !important;
        font-weight: normal !important;
        width: 100% !important;
    }
    .inner-con h6{
        font-size: 1.3em !important;
        font-weight: normal !important;
    }
    
}