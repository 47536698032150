:root{
  --default-bg: #1e1e20;
  --bg: #1c2338;
  --bg-gray: #32383f;
  --bg-accent: #293c5d;
  --bg-dark: #0d1415;
  --nav-size: 60px; 
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
  
  --font-family: inter,sans-serif;
  --font-weight-large: 650;
  --font-color-light: rgb(236, 247, 243);

  --text-color: rgb(245, 239, 239);
  --font-family-advanced: 'Shadows Into Light', cursive;
  --font-family-advanced-2: 'Signika Negative', sans-serif; 
  --font-family-advanced-3: 'Quicksand', sans-serif;
  --font-family-advanced-4: 'Sarpanch', sans-serif;
  --font-family-advanced-5: 'PT Mono', monospace;
  --font-family-advanced-6: 'Pacifico', cursive;
  --font-family-advanced-7: 'Megrim', cursive;
  --font-family-advanced-8: 'Anek Latin', sans-serif;
  --font-family-advanced-9: 'Genos', sans-serif;


  --App-V2-color-blue-0: #011c2e;
  --App-V2-color-blue-1: #23405a;
  --App-V2-color-blue-2: #445f7b;
  --App-V2-color-blue-3: #657f9d;
  --App-V2-color-blue-4: #aac5e5;
  --App-V2-color-blue-5: #d9e3ee;
  
}

@-moz-document url-prefix() { /* Disable scrollbar Firefox */
  html{
      scrollbar-width: none;
      scroll-behavior: smooth !important;
  }

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: var(--default-bg);
  background-color: var(--App-V2-color-blue-0);
  color: #ffffff;
  display: flex;
  justify-content: center; 

  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  scroll-behavior: smooth !important;
  padding: 0;
  margin: 0;
  min-width: none;
  display: block;
}

::-webkit-scrollbar {
  display: none;
}
body, html {height: 100%; background-color: #1b1b1b; scroll-behavior: smooth !important;}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p{
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: normal !important;
  font-size: 1.3rem !important;
}
li{font-family: Arial, Helvetica, sans-serif !important;}
h1{font-family: Arial, Helvetica, sans-serif !important;} 
legend{font-family: Arial, Helvetica, sans-serif !important;} 
span{font-family: Arial, Helvetica, sans-serif !important;} 
a{font-family: Arial, Helvetica, sans-serif !important; color: var(--text-color); text-decoration: none;} 

/*
font-family: 'Anek Latin', sans-serif;
font-family: 'Megrim', cursive;
font-family: 'Pacifico', cursive;
font-family: 'PT Mono', monospace;
font-family: 'Quicksand', sans-serif;
font-family: 'Sarpanch', sans-serif;
font-family: 'Shadows Into Light', cursive;
font-family: 'Signika Negative', sans-serif;  
*/

ul{
  font-family: Arial, Helvetica, sans-serif !important;
  margin: 0; padding: 0; list-style: none;
}
a{
  text-decoration: none !important; 
}
p{
  font-family: Arial, Helvetica, sans-serif;
  color: #fff ;
}
