.app_column_container{
    padding-top: 80px;
    color: #fff;
    display: flex;
    font-weight: bold !important;
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin: 25px;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(8px) !important;
    border-radius: 10px;
    font-family: var(--font-family);
    font-size: 1.1em;

}
.app_column_container h1{
    font-size: 1.7rem !important;
    font-weight: normal !important;
}
.animate-selector{
    margin-top: 150px !important;
    margin-bottom: 150px !important;
    margin-left: calc(20% - 100px);
    transition: transform 0.8s, opacity 0.5s;
}
.animate-selector.left{
    transform: translateY(120px);
    opacity: 0;
}
.zero-opacity{
    opacity: 0;
}
.app_column_container p{
    font-weight: normal !important;
    font-size: 1rem;
}
.app_column_container h1{
    font-size: 2.5rem !important;
}
@media only screen and(max-width: 768px) {
    .app_column_container h1{
        font-size: 1.9rem !important;
    }   
    .app_column_container h2{
        font-size: 1.9rem !important;
    }  
}
.animate-selector.active{
    margin: 25px;
    transform: translateY(0);
    opacity: 1;
}
.container__svg_app{
    width: inherit;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.domain-check-input{
    width: inherit;
    height: 50px;
    border: 1px solid black;
    color: rgb(12, 11, 11);
    font-family: 'Arial, sans-serif, verdana';
    font-size: 15px;
}
.domain-check-button{
    border-radius: 10px;
    border: 1px solid rgb(245, 242, 242) ;
    color: #000 ;
    width: auto ;
    height: 20px ;
    display: flex;
    text-align: center;
    cursor: pointer;
}
.Available{
    color: #193;
}
.notAvailable{
    color: #932;
}
.__avail{
    border-radius: 10px;
    border: none;
    background-color: rgb(45,45,45);
}
.__avail:focus{
    border-radius: 5px;
    border: none;
    background-color: rgb(45,45,45);
}
.__avail_btn{
    width: inherit;
    height: 40px;
    border-radius: 10px;
    background-color: rgba(26, 24, 24, 1);
    color: rgb(243, 236, 236);
    font-weight: normal;
    font-size: 15px;
    font-family: var(--font-family);
    padding: 3px;
    cursor: pointer;
    transition: all ease-in-out .2s;
    border: 1px solid rgb(255, 255, 255);
}
.__avail_btn:hover{
    border: 1px solid rgba(255, 255, 255,0);

    background-color: rgb(65, 62, 62);
    color: #fff;
}
#check-domain input{
    height: 40px;
    text-align: center;
    margin: 5px;

}
#check-domain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
@media only screen and(max-width: 768px){
    .app_column_container h1{
        font-size: 1.9rem !important;
    }
    .app_column_container h2{
        font-size: 1.9rem !important;
    }  
    .app_column_container{
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
    }
    .Content__Column{
        font-size: 1em;
    }
    .active{
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
    }
}
@media only screen and(min-width: 1000px){
    .app_column_container p{
        font-size: 1.3em;
    }
    
}
