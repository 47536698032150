$marketing-site-three-up-width: 900px;
$primary-color: rba7(170,170,170);
.large-12{
  border: none !important;
}
.marketing-site-three-up {
  text-align: center;
  padding-left:  .5rem;
  padding-right: .5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;


  .row {
    max-width: $marketing-site-three-up-width;
  }

  .columns {
    padding: 20px;
    max-width: 780px !important;
    margin-bottom: 20px !important;
    background-color: rgba(46, 46, 46, 0.7) !important;
    border-radius: 15px !important;
    backdrop-filter: blur(5px) !important;
    border-radius: 5px;

    @media screen and (min-width: 40em) {
      margin-bottom: 0;
    }
  }

  .fa {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: $primary-color;
  }
}

.marketing-site-three-up-headline {
  margin-bottom: 2rem;
  font-size: 24px;
}
.description_text{
  font-size: 1.3rem !important;

}
.callout-box{
  text-align: center !important;
  background-color: rgba(45,45,45,0);
  color: rgba(255,255,255);
  border: none !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  width: 1000px !important;
  backdrop-filter: blur(2px) !important;
  background-color: #09090940 !important;
  border-radius: 15px !important;
  
}
.no-deer{
  background-color: #010101 !important;
}

.glas-card-effect{
  border: .02rem solid rgba(255, 255, 255, 0);
  color: rgb(216, 216, 216) ;
}
.glas-card-effect p{
  color: rgb(216, 216, 216) ;
}

@media only screen and (min-width: 1024px){
  .columns p{
    font-size: 1.2rem !important;
    font-weight: normal !important;
  }
  .callout-box{
    width: 900px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;

  }
}
@media only screen and (max-width: 768px){
  .columns p{
    font-size: 1rem !important;
    font-weight: normal !important;
  }
  // MOBILE
  .callout-box{
    margin-left: 20px !important; margin-right: 20px !important;
    width: 100% !important;
    margin-left: 20px !important;
    margin-right: 20px !important;

  }
  .callout{
    padding: 20px !important;
  }
}
