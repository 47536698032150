
.row{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 20px !important;
    flex-direction: column !important;
}
.__content{
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important
}
.callout{
    border: none !important;
    margin: 20px !important;
}